import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { GoogleAnalyticsService } from './shared/services';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'photomaton-lausanne-ch' }),
    NgbModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
  ],
  providers: [
    GoogleAnalyticsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

// Locale support
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import { HttpClientModule } from '@angular/common/http';


registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEs, 'es');
