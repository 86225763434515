<core-header class="fixed-top"></core-header>
<a
  id="language-switch"
  *ngIf="notNavigatorLanguage && navigator"
  class="d-block text-center text-decoration-none link-like py-2"
  href="https://photomaton-lausanne.ch/{{ navigator.language.split('-')[0] }}/"
>
  <ng-container [ngSwitch]="navigator.language.split('-')[0]">
    <span *ngSwitchCase="'en'">Switch language to english</span>
    <span *ngSwitchCase="'de'">Die Sprache auf Deutsch umschalten</span>
    <span *ngSwitchCase="'fr'">Changer la langue en français</span>
    <span *ngSwitchCase="'es'">Cambiar el idioma a francés</span>
  </ng-container>
</a>
<div id="page" class="d-flex flex-column">
  <div class="flex-grow-1">
    <router-outlet></router-outlet>
  </div>
  <core-footer></core-footer>
</div>
<google-analytics-gtag></google-analytics-gtag>
