<div class="container-fluid py-3">
  <div class="row g-0">
    <!-- Copyright notice on the left on desktop devices -->
    <div
      class="d-none d-lg-flex col-auto p-2 justify-content-center align-items-center small"
      i18n="copyright|copyright notice@@core.footer.copyright-desktop"
    >
      © 2023 Photomaton Lausanne. All rights reserved
    </div>
    <div class="col-auto flex-grow-1"></div>
    <!-- <div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <a
        routerLink="/tos"
        class="btn btn-link text-decoration-none link-like p-2"
        i18n="tos-link|Terms of service link@@core.footer.tos-link"
        >Terms of Service</a
      >
    </div>-->
    <div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <a
        routerLink="/privacy"
        class="btn btn-link text-decoration-none link-like p-2"
        i18n="privacy-link|Privacy statement link@@core.footer.privacy-link"
        >Privacy Statement</a
      >
    </div>
    <!--<div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <a
        routerLink="/legal"
        class="btn btn-link text-decoration-none link-like p-2"
        i18n="legal-link|legal notice link@@core.footer.legal-link"
        >Legal</a
      >
    </div> -->
    <!-- Copyright notice on the bottom on mobile devices -->
    <div
      class="d-flex d-lg-none col-12 p-2 justify-content-center align-items-center small pt-4"
      i18n="copyright|copyright notice@@core.footer.copyright-mobile"
    >
      © 2023 Photomaton Lausanne. All rights reserved
    </div>
  </div>
  <div class="row g-0">
    <!-- Contact info on the left on desktop devices -->
    <div
      class="d-none d-lg-flex col-auto p-2 justify-content-center align-items-center small"
      i18n="contact|contact info@@core.footer.contact-desktop"
    >
      <div>
        <div class="fw-bold">Contact Us</div>
        <div>Cyrill Raccaud, Ch. de Bérée 4D, 1010 Lausanne</div>
        <div>
          <a href="mailto:photomaton.lausanne@gmail.com"
            >photomaton.lausanne@gmail.com</a
          >
        </div>
      </div>
    </div>
    <div class="col-auto flex-grow-1"></div>
    <div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <a
        href="https://photomaton-lausanne.ch/en/"
        class="btn btn-link text-decoration-none link-like p-2"
        >en</a
      >
      <a
        href="https://photomaton-lausanne.ch/fr/"
        class="btn btn-link text-decoration-none link-like p-2"
        >fr</a
      >
      <a
        href="https://photomaton-lausanne.ch/de/"
        class="btn btn-link text-decoration-none link-like p-2"
        >de</a
      >
      <a
        href="https://photomaton-lausanne.ch/es/"
        class="btn btn-link text-decoration-none link-like p-2"
        >es</a
      >
    </div>
    <!-- Contact info on the bottom on mobile devices -->
    <div
      class="d-flex d-lg-none col-12 p-2 justify-content-center text-center align-items-center small pt-4"
      i18n="contact|contact notice@@core.footer.contact-mobile"
    >
      <div>
        <div class="fw-bold">Contact Us</div>
        <div>Cyrill Raccaud, Ch. de Bérée 4D, 1010 Lausanne</div>
        <div>
          <a href="mailto:photomaton.lausanne@gmail.com"
            >photomaton.lausanne@gmail.com</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
