import { Component, ElementRef, HostListener, ViewChild, LOCALE_ID, Inject } from '@angular/core';

@Component(
{
  selector: 'core-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
{
  mobileMenuIsCollapsed = true;

  mobileProductsMenuIsCollapsed = true;

  @ViewChild('container') containerEl: ElementRef<HTMLElement> | undefined;

  @ViewChild('topRow') topRowEl: ElementRef<HTMLElement> | undefined;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll()
  {
    const containerEl = this.containerEl?.nativeElement;
    const topRowEl = this.topRowEl?.nativeElement;

    if (!containerEl || !topRowEl) return;
    if (window.scrollY > topRowEl.clientHeight * 1.5)
    {  // Start transition after 1.5x header height, this is an arbitrary value
      containerEl.classList.add('force-bg');
    }
    else
    {
      containerEl.classList.remove('force-bg');
    }
  }

  constructor(
    @Inject(LOCALE_ID) public locale: string,
  ) { }
}
