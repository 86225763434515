<div
  #container
  id="menu-container"
  class="position-fixed top-0 w-100"
  [class.mobile-expanded]="!mobileMenuIsCollapsed"
>
  <div #topRow class="row g-0 ps-4 pe-2">
    <!-- Top-left logo -->
    <div class="col-auto d-flex align-items-center">
      <a class="link-like text-decoration-none fw-bold" routerLink="/">
        <img
          id="logo"
          alt="Photomaton Lausanne Logo"
          src="assets/media/logo.svg"
          aria-label="Logo"
        />
        <span
          i18n="logo|logo link@@core.header.logo-desktop"
          class="ms-4 text-uppercase"
          >Photomaton Lausanne</span
        >
      </a>
    </div>
    <div class="col-auto flex-grow-1"></div>

    <!-- Menu items in navbar on desktop devices -->
    <div class="col-auto d-none d-md-block">
      <a
        class="btn btn-link text-primary text-decoration-none link-like p-3"
        routerLink="/choose"
        routerLinkActive="fw-bold"
        [routerLinkActiveOptions]="{ exact: true }"
        i18n="book-now|Book now link@@core.header.book-now-desktop"
        >Book now →</a
      >
    </div>
    <div class="col-auto d-none d-md-block">
      <a
        class="btn btn-link text-primary text-decoration-none link-like p-3"
        href="mailto:photomaton.lausanne@gmail.com"
        i18n="contact-link|contact link@@core.header.contact-link-desktop"
        >Contact Us</a
      >
    </div>

    <!-- Hamburger menu on mobile devices -->
    <div class="col-auto d-md-none">
      <button
        type="button"
        class="btn btn-link text-primary text-decoration-none link-like mx-1 p-3"
        (click)="mobileMenuCollapse.toggle()"
        aria-label="Toggle menu"
        [attr.aria-expanded]="!mobileMenuIsCollapsed"
        aria-controls="mobileMenuCollapse"
      >
        <span *ngIf="mobileMenuIsCollapsed">
          <svg
            class="icon"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 6C3 5.44772 3.44772 5 4 5H19C19.5523 5 20 5.44772 20 6C20 6.55228 19.5523 7 19 7H4C3.44772 7 3 6.55228 3 6Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 11.5C3 10.9477 3.44772 10.5 4 10.5H19C19.5523 10.5 20 10.9477 20 11.5C20 12.0523 19.5523 12.5 19 12.5H4C3.44772 12.5 3 12.0523 3 11.5Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 17C3 16.4477 3.44772 16 4 16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H4C3.44772 18 3 17.5523 3 17Z"
            />
          </svg>
        </span>
        <span *ngIf="!mobileMenuIsCollapsed">
          <svg
            class="icon"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.7071 4.70711C20.0976 4.31658 20.0976 3.68342 19.7071 3.29289C19.3166 2.90237 18.6834 2.90237 18.2929 3.29289L11.5 10.0858L4.70711 3.29289C4.31658 2.90237 3.68342 2.90237 3.29289 3.29289C2.90237 3.68342 2.90237 4.31658 3.29289 4.70711L10.0858 11.5L3.29289 18.2929C2.90237 18.6834 2.90237 19.3166 3.29289 19.7071C3.68342 20.0976 4.31658 20.0976 4.70711 19.7071L11.5 12.9142L18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L12.9142 11.5L19.7071 4.70711Z"
            />
          </svg>
        </span>
      </button>
    </div>
  </div>
  <!-- Content of the mobile hamburger menu -->
  <div
    #mobileMenuCollapse="ngbCollapse"
    [(ngbCollapse)]="mobileMenuIsCollapsed"
    class="row d-md-none g-0 justify-content-center w-100 pe-auto mb-3"
  >
    <div class="col-auto w-100">
      <div class="row g-0 justify-content-center">
        <div class="col-auto w-100">
          <a
            class="btn btn-link text-primary text-decoration-none link-like w-100"
            routerLink="/choose"
            routerLinkActive="fw-bold"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="
              mobileMenuIsCollapsed = true; mobileProductsMenuIsCollapsed = true
            "
            i18n="book-now|Book now link@@core.header.book-now-mobile"
            >Book now →</a
          >
        </div>
      </div>
      <div class="row g-0 justify-content-center">
        <div class="col-auto w-100">
          <a
            class="btn btn-link text-primary text-decoration-none link-like w-100"
            href="mailto:photomaton.lausanne@gmail.com"
            (click)="
              mobileMenuIsCollapsed = true; mobileProductsMenuIsCollapsed = true
            "
            i18n="contact-link|contact link@@core.header.contact-link-mobile"
            >Contact Us</a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Background blocker when mobile menu is active -->
<div
  *ngIf="!mobileMenuIsCollapsed"
  class="position-fixed p-2 top-0 w-100 h-100 d-md-none bg-light opacity-75"
  (click)="mobileMenuCollapse.toggle()"
></div>
