import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { GoogleAnalyticsService } from './shared/services';

let navigator: Navigator;
try { navigator = window.navigator }
catch { console.info('No window found!') }

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit
{
  navigator = navigator ? navigator : undefined;

  notNavigatorLanguage = navigator ? !navigator.language.startsWith(this.locale) : false;

  subscriptions = new Subscription();

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    @Inject(LOCALE_ID) public locale: string,
    public router: Router,
    public googleAnalyticsService: GoogleAnalyticsService,
  )
  {

  }

  ngAfterViewInit(): void
{
    this.subscriptions.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe(event =>
{
          if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd)
{
            gtag('config', this.googleAnalyticsService.trackingCode, { 'page_path': event.urlAfterRedirects });
          }
        },
      ),
    );
  }
}
