/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ICAL from 'ical.js';
import moment from 'moment';

export interface EventItem
{
  name: string;
  photomaton: string;
  from: moment.Moment;
  to: moment.Moment;
}


@Injectable({
  providedIn: 'root',
})
export class EventsService
{
  constructor(private readonly http: HttpClient) { }

  public events(): Observable<EventItem[]>
  {
    const icsFilePath = `assets/events.ical?${ moment().unix() }`;
    return this.http.get(icsFilePath, { responseType: 'text' }).pipe(
      map((text) =>
      {
        const jcalData = ICAL.parse(text);
        const comp = new ICAL.Component(jcalData);
        return comp.getAllSubcomponents("vevent").map((sub: any) =>
        {
          const item = new ICAL.Event(sub);
          return {
            name: item.summary,
            photomaton: item.description,
            from: moment(item.startDate.toJSDate() as string),
            to: moment(item.endDate.toJSDate() as string),
          }
        }) as EventItem[];
      }))
  }
}
