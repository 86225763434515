// Reference: https://matcha.fyi/google-analytics-angular/

import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

export interface SeoPageInfo
{
  /** The title should be <60 characters */
  title: string,
  /** The description should be < 160 characters */
  description: string,
  /** Ignore for SEO (send robots noindex). Default: false */
  ignore?: boolean,
}

@Injectable({
  providedIn: 'root',
})
export class SeoService
{

  constructor(
    private readonly meta: Meta,
    private readonly title: Title,
  )
{
  }

  run(info: SeoPageInfo): void
{
    this.title.setTitle(info.title);
    this.meta.addTags([
      { name: 'description', content: info.description },
      { name: 'author', content: 'Cyrill Raccaud' },
      { name: 'robots', content: 'max-image-preview:large' },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      { name: 'og:locale', content: $localize`:locale|Locale@@seo.locale:en:US` },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      { name: 'og:site_name', content: 'Photomaton Lausanne' },
      { name: 'og:type', content: 'article' },
      { name: 'og:title', content: info.title },
      { name: 'og:description', content: info.description },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      { name: 'og:url', content: $localize`:url|Site url@@seo.url:https://photomaton-lausanne.ch/en` },
      { name: 'og:image', content: 'https://photomaton-lausanne.ch/en/assets/media/social/Logo_512h.png' },
      { name: 'og:image:secure_url', content: 'https://photomaton-lausanne.ch/en/assets/media/social/Logo_512h.png' },
      { name: 'og:image:width', content: '512' },
      { name: 'og:image:height', content: '512' },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:title', content: info.title },
      { name: 'twitter:description', content: info.description },
      { name: 'twitter:image', content: 'https://photomaton-lausanne.ch/en/assets/media/social/Logo_512h.png' },
    ]);
    if (info.ignore) this.meta.addTag({ name: 'robots', content: 'noindex' });
  }
}
